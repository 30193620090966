
import { Vue, Component } from 'vue-property-decorator'
import { Form } from 'view-design'

@Component({
  name: 'AddBuildMountDirsModal',
  components: {}
})
export default class AddBuildMountDirsModal extends Vue {
  isShow = false

  formRules = {
    key: [
      { trigger: 'blur', required: true, message: '请输入key值' }
    ],
    value: [
      { trigger: 'blur', required: true, message: '请输入value值' }
    ]
  }

  loading = false

  /**
   * 当前表单数据
   */
  data: { key: string; value: string } = { key: '', value: '' }

  show () {
    this.isShow = true
  }

  close () {
    this.isShow = false
  }

  doCancel () {
    this.$emit('onCancel')
  }

  doOk () {
    const checkform = this.$refs.form as Form
    checkform.validate((valid) => {
      if (valid) {
        this.$emit('onOk', { key: '/caches/' + this.data.key, value: this.data.value })
      }
    })
  }
}
