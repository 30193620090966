
import { Component, Vue } from 'vue-property-decorator'
import ApplicationBaseInfo from './application-base-info.vue'
import ApplicationDefaultConfig from './application-default-config.vue'
import { ApplicationType, Application } from '@/api/maintain/application'

@Component({
  name: 'ApplicationTabsPage',
  components: { ApplicationBaseInfo, ApplicationDefaultConfig }
})
export default class ApplicationTabsPage extends Vue {
  // applicationId
  id = '';
  // 应用类型是否为非前端项目
  notFront = true;
  /// 当前tab页
  tabName='name1'
  /// 是否只读
  viewFlag=true
  /// 应用类型
  appType?: ApplicationType=ApplicationType.WebApi

  getAppInfo (val: Application) {
    this.appType = val.appType
    this.viewFlag = !val.canOperation
    if (val.appType === ApplicationType.WebFront || val.appType === ApplicationType.NotStandardWebFront) this.notFront = false
    else this.notFront = true
  }

  back () {
    this.$router.back()
  }

  created () {
    this.id = this.$route.params.id
    this.viewFlag = true
  }

  deactivated () {
    this.tabName = 'name1'
  }
}
