
import request, { DataApiResult } from '@/libs/http-request'

// git仓库类型
export type GitRepositoryType = 'back' | 'front' | 'app'

/**
 * 项目git信息
 */
export class ProjectGitInfoApi {
  /**
     * 取得项目的所有分支列表
     * @param projectId
       * @param type
     * @returns
     */
  getProjectBranches (projectId: number, type: GitRepositoryType = 'back') {
    return request<DataApiResult<string[]>>({
      url: `/code/gitinfo/branches/${projectId}/${type}`,
      method: 'GET'
    })
  }

  /**
   * 取得项目远程仓库是否已经创建
   * @param projectId
   * @param branch
   * @param type
   * @returns
   */
  checkProjectGitRepsBranch (projectId: number, branch: string, type: GitRepositoryType = 'back') {
    return request<DataApiResult<boolean>>({
      url: `/code/gitinfo/checkProjectGitRepsBranch/${projectId}/${type}/${branch}`,
      method: 'GET'
    })
  }

  /**
   * 取得仓库分支信息
   * @param gitUrl
   * @param userName
   * @param password
   * @returns
   */
  getBranches (gitUrl: string, userName: string, password: string, projectId?: number, appId?: number) {
    return request<DataApiResult<string[]>>({
      url: '/code/gitinfo/branches',
      method: 'POST',
      data: {
        gitUrl: gitUrl,
        userName: userName,
        password: password,
        projectId: projectId === null ? undefined : projectId,
        applicationId: appId === null ? undefined : appId
      }
    })
  }

  /**
   * 取得仓库标签信息
   * @param gitUrl
   * @param userName
   * @param password
   * @returns
   */
  getTags (gitUrl: string, userName: string, password: string, proId?: number, appId?: number) {
    return request<DataApiResult<string[]>>({
      url: '/code/gitinfo/tags',
      method: 'POST',
      data: {
        gitUrl: gitUrl,
        userName: userName,
        password: password,
        projectId: proId === null ? undefined : proId,
        applicationId: appId === null ? undefined : appId
      }
    })
  }
}

export default new ProjectGitInfoApi()
