
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import projectGitInfoApi from '@/api/code-generate/project-gitinfo'

/**
 * 字段选择器
 */
@Component({
  name: 'GitBranchSelector'
})
export default class extends Vue {
  @Prop({
    type: String,
    required: false
  })
  readonly gitUrl!: string

  @Prop({
    type: String,
    required: false
  })
  readonly userName!: string

  @Prop({
    type: String,
    required: false
  })
  readonly password!: string

  @Prop({
    type: Number,
    required: false
  })
  readonly applicationId!: number

  @Prop({
    type: Number,
    required: false
  })
  readonly projectId!: number

  /** 分支加载完成 */
  branchLoaded = false
  /** 分支 */
  branches: string[] = []
  /** 数据是否为最新 */
  lastestData=false

  get dataChange () {
    return {
      gitUrl: this.gitUrl,
      userName: this.userName,
      password: this.password,
      applicationId: this.applicationId,
      projectId: this.projectId === null ? undefined : this.projectId
    }
  }

  mounted () {
    this.$nextTick(() => {
      this.loadBranch()
    })
  }

  @Watch('dataChange')
  onGitUrl () {
    this.lastestData = false
    this.branches = []
  }

  /**
   * 下拉框展开事件
   * @param open
   */
  openChange (open: boolean) {
    if (open && !this.lastestData) {
      this.loadBranch()
    }
  }

  loadBranch () {
    if (this.gitUrl && this.userName && this.password) {
      this.branchLoaded = true
      this.lastestData = true
      projectGitInfoApi.getBranches(this.gitUrl, this.userName, this.password, this.projectId, this.applicationId).then((res) => {
        this.$Message.success('仓库分支查询成功')
        this.branches = res.data || []
        this.branchLoaded = false
      }).catch(() => {
        this.branches = []
        this.$Notice.error({
          title: '失败',
          desc: '仓库分支查询失败'
        })
        this.branchLoaded = false
      })
    }
  }
}
