import { showModal } from '@/libs/modal-helper'
import modal from './add-build-mount-dirs-modal.vue'

export default function showAddBuildMountDirsModal (): Promise<{ key: string; value: string }> {
  return new Promise<{ key: string; value: string }>((resolve, reject) => {
    showModal<{ key: string; value: string }>(
      modal,
      {},
      true,
      (data: { key: string; value: string }) => {
        resolve(data)
        return new Promise<void>((resolve, reject) => resolve())
      }, () => {
        reject(new Error('用户取消'))
      })
  })
}
