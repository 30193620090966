
import { Component, Prop, Vue } from 'vue-property-decorator'
import ApplicationApi, { Application } from '@/api/maintain/application'
import ImagesResourceApi, { ImagesResource } from '@/api/maintain/image-resource'
import GitBranchSelector from '@/components/selector/git-branch-selector.vue'
import GitTagSelector from '@/components/selector/git-tag-selector.vue'
import { Form } from 'view-design'
import UploadButton from '@/components/upload-button.vue'
import { AttachmentFileData } from '@/api/maintain/attachment-filedata'
import { getterUserInfo } from '@/store'
import showAddBuildMountDirsModal from './modal/show-add-build-mount-dirs-modal'

@Component({
  components: { GitBranchSelector, GitTagSelector, UploadButton },
  name: 'ApplicationBaseInfo'
})
export default class ApplicationBaseInfo extends Vue {
  // applicationId
  @Prop({
    type: Number,
    required: true
  })
  readonly id!: number

  // 初始Data
  restData: Application = {} as Application
  // 表单Data
  appData: Application = {} as Application
  // 镜像资源options
  imageList: ImagesResource[] = []
  // tag标签
  tags: string[] = []
  // 挂载目录
  dirs: string[] = []
  // tag内容
  tag = ''
  // 查看标识 ture为查看状态, false为编辑状态
  viewFlag = true
  /// 显示修改密码模态框
  showModel=false
  /// 提交loading
  loading = false

  /// 新git密码
  newGitPwd=''

  rules = {
    name: [{ required: true, message: '应用名称不允许为空', trigger: 'blur' }],
    gitUrl: [{ required: true, message: '请填写git地址', trigger: 'blur' }],
    gitUser: [{ required: true, message: '请填写git账号', trigger: 'blur' }],
    gitPwd: [{ required: true, message: '请填写git密码', trigger: 'blur' }],
    gitBranch: [{ required: true, validator: this.validateGit, trigger: 'blur' }],
    gitTag: [{ required: true, validator: this.validateGit, trigger: 'blur' }]
  }

  /** 获取显示tag列表 */
  get showTags () {
    if (this.tags.length > 0) {
      return this.tags
    }
    return ['暂未设置标签']
  }

  get userInfo () {
    return getterUserInfo()
  }

  get canEditServier () {
    return !!this.userInfo?.canOperatePcServer
  }

  /** 上传的等待事件 */
  onLoading (val: boolean) {
    this.loading = val
  }

  changeEditMode () {
    this.viewFlag = false
    // this.$emit('getViewFlag', false)
  }

  /// git分支校验
  validateGit (rule: any, value: string, callback: Function) {
    if (!this.appData.gitBranch && !this.appData.gitTag) {
      return callback(new Error('分支和标签不允许同时为空!'))
    }
    return callback()
  }

  created () {
    ApplicationApi.getItemById(this.id).then((res) => {
      this.tags = res.data!.tags?.split(',').filter((e) => e.match(/\S/)) || []
      this.dirs = res.data!.buildMountDirs?.split(',') || []
      this.appData = res.data!
      this.$nextTick(() => {
        if (this.$refs.gitBranceSelector) {
          ;(this.$refs.gitBranceSelector as any).loadBranch()
        }
        if (this.$refs.gitTagSelector) {
          ;(this.$refs.gitTagSelector as any).loadTag()
        }
      })

      this.restData = JSON.parse(JSON.stringify(res.data as Application))
      this.$emit('getAppInfo', this.appData)
    })
    ImagesResourceApi.query({ page: 1, pageSize: 1000, sorts: [] })
      .then(response => {
        this.imageList = response.data!.items
      })
  }

  /** 上传文件成功 */
  uploadOk (val: AttachmentFileData) {
    this.$set(this.appData, 'buildDockfileUrl', `attr:${val.id}`)
  }

  /// 确定修改
  ok () {
    if (!this.newGitPwd.match(/\S/)) {
      this.$Message.error('新密码不能为空')
      return
    }
    if (this.appData.id) {
      ApplicationApi.updateGitPwd(this.appData.id, this.newGitPwd).then((res) => {
        this.appData.gitPwd = res.data?.gitPwd
        this.appData.version = res.data?.version
        this.showModel = false
        this.$Message.success('修改成功')
      }).catch((err) => {
        this.$Message.error(err.message)
      })
    }
  }

  /// 取消修改
  cancel () {
    this.showModel = false
  }

  addTags () {
    if (this.tag) {
      this.tags.push(this.tag)
      this.tag = ''
    }
  }

  closeTag (index: number) {
    this.tags.splice(index, 1)
  }

  /** 获取显示挂载目录列表 */
  get showDirs () {
    if (this.dirs.length > 0) {
      return this.dirs
    }
    return ['暂未设置挂载目录']
  }

  /** 添加部署挂载目录 */
  addBuildMountDirs () {
    showAddBuildMountDirsModal().then(res => {
      this.dirs.push(`${res.key}&${res.value}`)
    })
  }

  /** 删除挂载目录 */
  closeDirs (index: number) {
    this.dirs.splice(index, 1)
  }

  // 重置表单
  handleRest () {
    this.appData = JSON.parse(JSON.stringify(this.restData))
    this.tags = this.appData!.tags?.split(',').filter((e) => e.match(/\S/)) || []
  }

  /// 修改git密码
  uptGitPwd () {
    this.showModel = true
    this.newGitPwd = ''
  }

  // 保存表单
  handleSubmit () {
    this.appData.tags = this.tags.join(',')
    this.appData.buildMountDirs = this.dirs.join(',')
    ;(this.$refs.appForm as Form).validate((res) => {
      if (res) {
        this.handleSave()?.then(response => {
          if (response.data) {
            this.appData = response.data
          }
          this.$Notice.success({ desc: '保存成功' })
        })
          .catch(err => {
            this.$Notice.error({ desc: '保存失败' + err.toString() })
          })
      }
    })
  }

  /**
   * 执行保存操作
   */
  handleSave () {
    switch (this.appData.appType) {
      case 1:
      case 2:
        return ApplicationApi.updateStandApp(this.appData)
      case 3:
      case 6:
        return ApplicationApi.updateNoStandApp(this.appData)
      case 4:
      case 5:
        return ApplicationApi.updateImageApp(this.appData)
    }
  }
}
